<!--  -->
<template>
  <div class="marketpage">
    <h6>{{$t('vue_label_systemSettings_pagefooter')}}</h6>
    <div class="text">
      <div class="textarea">
        <p>{{ compname }}</p>
        <p>{{ address }}</p>
        <p>{{ location }}</p>
        <p>{{ city }}</p>
        <p>{{ omit }}</p>
        <p>{{ postalcode }}</p>
        <p>{{ country }}</p>
        <p>{{ phone }}</p>
      </div>
      <button @click="drawer = true">{{$t('label.edit')}}</button>
    </div>
    <el-drawer title="" size="35%" :visible.sync="drawer" :with-header="false">
      <div class="header">
        <div class="headleft">
          <span class="back" @click="close"
            ><i class="el-icon-arrow-left"></i
          ></span>
          <span class="shulan">|</span>
          邮件页脚
        </div>
        <div class="headright">
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>
      <div class="contauto">
        <div class="content">
          <p><span>*</span>{{$t('label.sms.companyname')}}</p>
          <input type="text" v-model="compname" />
          <p><span>*</span>{{$t('label.address')}}</p>
          <input type="text" v-model="address" :placeholder="$t('label.address.street')" />
          <p>{{$t('label.address_linetwo')}}</p>
          <input type="text" v-model="location" :placeholder="$t('c38')" />
          <p><span>*</span>{{$t('label.city')}}</p>
          <input type="text" v-model="city" :placeholder="$t('label.city')" />
          <p><span>*</span>{{$t('label.address.province')}}</p>
          <input type="text" v-model="omit" :placeholder="$t('label.address.province')" />
          <p>{{$t('label.emailEncoding')}}</p>
          <input type="text" v-model="postalcode" />
          <p>{{$t('label.country')}}</p>
          <input type="text" v-model="country" />
          <p>{{$t('label.h5.phone')}}</p>
          <input type="text" v-model="phone" />
        </div>
      </div>
      <div class="footer">
        <button @click="close">{{$t('label.tabpage.cancel')}}</button>
        <button class="buttonsave" @click="save">{{$t('label.chatter.save')}}</button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import * as marketChannel from "./api";//api
export default {
  components: {},
  data() {
    return {
      drawer: false,
      compname: '',//公司名称
      address: '',//地址
      location: '',//第二行地址
      city: '',//城市
      omit: '',//省
      postalcode: '',//邮政编码
      country: '',//国家
      phone: '',//电话
      id: 'MarketcloudBaseInfo',
    };
  },
  computed: {},
  watch: {},
  //方法集合
  created() {
    this.getdata()
  },
  methods: {
    close() {
      this.drawer = false;
    },
    save() {
      let obj = {
        id: this.id,
        name: '',
        companyName: this.compname,
        companyCountry: this.country,
        companyCity: this.city,
        companyState: this.omit,
        companyZipcode: this.postalcode,
        companyStreet: this.address,
        companyDoorplate: this.location,
        companyPhone: this.phone,
      }
      if (this.compname !== '' && this.address !== '' && this.city !== '' && this.omit !== '') {
        marketChannel.saveSettingDetail(obj).then((res) => {
          if (res.result) {
            this.drawer = false;
          }
        })
          .catch(() => { return false })
      }
    },
    getdata() {
      let obj = {
        id: this.id,
      }
      marketChannel.getSettingDetail(obj).then((res) => {
        if (res.result) {
          this.compname = res.data.companyName;
          this.country = res.data.companyCountry;
          this.city = res.data.companyCity;
          this.omit = res.data.companyState;
          this.postalcode = res.data.companyZipcode;
          this.address = res.data.companyStreet;
          this.location = res.data.companyDoorplate;
          this.phone = res.data.companyPhone;
          this.id = res.data._id;
        }

      }).catch(() => { return false })
    }
  },
}
</script>
<style lang='scss' scoped>
.marketpage {
  h6 {
    width: 100%;
    padding-left: 10px;
    line-height: 52.8px;
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
    margin: 0;
  }
  .text {
    background: #ffffff;
    border: 1px solid #dddbda;
    border-radius: 2px;
    width: 692px;
    height: 310px;
    margin-left: 10px;
    .textarea {
      border: none;
      outline: none;
      resize: none;
      width: 97%;
      margin-left: 1.5%;
      min-height: 275px;
      margin-top: 10px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
    }
    button {
      border: none;
      outline: none;
      margin-left: 10px;
      background: none;
      cursor: pointer;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #0771ce;
      letter-spacing: 0;
    }
  }
  .header {
    height: 6%;
    width: 100%;
    border: 1px solid #dedcda;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headleft {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #3e3c3c;
      letter-spacing: 0;
      span {
        transform: rotate(-45deg);
        padding-left: 10px;
        padding-right: 10px;
        color: #888888;
      }
      .shulan {
        color: #dedcda;
      }
      .back {
        cursor: pointer;
      }
    }
    .headright {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #3e3c3c;
      letter-spacing: 0;
      padding-right: 10px;
      i {
        cursor: pointer;
      }
    }
  }
  .contauto {
    height: 86%;
    overflow: auto;
    width: 100%;
  }
  .content {
    width: 93%;
    margin-left: 4%;
    p {
      width: 100%;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #151515;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 20px;
      span {
        color: #c23934;
        padding-right: 4px;
      }
    }
    input {
      outline: none;
      width: 97%;
      height: 41px;
      padding-left: 10px;
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #3e3c3c;
      letter-spacing: 0;
      margin-bottom: 10px;
    }
  }
  .footer {
    height: 7%;
    border-top: 1px solid #dedcda;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      outline: none;
      cursor: pointer;
      margin-right: 10px;
      border: 1px solid #dedcda;
      border-radius: 3px;
      background: #ffffff;
      padding: 9px 23px;
    }
    .buttonsave {
      background: #006dcc;
      border-radius: 3px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      margin-right: 10%;
    }
  }
}
</style>